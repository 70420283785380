<script setup lang="ts">
import { computed } from 'vue'

const emit = defineEmits(['update:checked'])

const props = defineProps<{
  checked: boolean;
  value?: any;
}>()

const proxyChecked = computed({
  get () {
    return props.checked
  },

  set (val) {
    emit('update:checked', val)
  },
})
</script>

<template>
  <input
    type="checkbox"
    :value="value"
    v-model="proxyChecked"
    class="bg-gray-800 border-gray-600 text-primary-600 checkmark-black shadow-sm focus:ring-primary-600 focus:ring-offset-gray-800"
  />
</template>
