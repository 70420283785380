<script setup lang="ts">
import Checkbox from '@/Components/Checkbox.vue'
import InputError from '@/Components/InputError.vue'
import PageContent from '@/Components/PageContent.vue'
import PrimaryButton from '@/Components/PrimaryButton.vue'
import TextInput from '@/Components/TextInput.vue'
import PageLayout from '@/Layouts/PageLayout.vue'
import { type Tenant } from '@/types'
import { Head, Link, useForm } from '@inertiajs/vue3'
import axios from 'axios'
import { nextTick, onMounted, ref } from 'vue'
import { browserSupportsWebAuthn, browserSupportsWebAuthnAutofill, startAuthentication } from "@simplewebauthn/browser"

const props = defineProps<{
  tenant?: Tenant;
  loggedOut?: boolean;
  canResetPassword?: boolean;
  status?: string;
}>()

const form = useForm({
  answer: '',
  username: '',
  password: '',
  remember: false,
})

const supportsWebAuthn = browserSupportsWebAuthn()
const showPasswordField = ref(! supportsWebAuthn)
const passwordField = ref()
const submitting = ref(false)

const usePassword = () => {
  showPasswordField.value = true
  nextTick(() => passwordField.value?.focus())
}

const submit = async (automaticSubmission: boolean = false) => {
  if (showPasswordField.value) {
    return form
      .transform(() => ({
        client_id: props.tenant?.id,
        username: form.username,
        password: form.password,
        remember: form.remember ? 'on' : '',
      }))
      .post('/login', {
        onFinish: () => {
          form.reset('password', 'answer')
        },
      })
  }

  if (form.username) {
    submitting.value = true
  }

  try {
    const options = await axios.get('/api/passkeys/authenticate', {
      params: { username: form.username },
    })

    if (! form.username || options.data.allowCredentials.length || automaticSubmission) {
      const answer = await startAuthentication(options.data, automaticSubmission)

      return form
        .transform(() => ({
          client_id: props.tenant?.id,
          answer: JSON.stringify(answer!),
          remember: form.remember ? 'on' : '',
        }))
        .post('/passkeys/authenticate', {
          onError () {
            if (! automaticSubmission) {
              usePassword()
            }
          }
        })
    }

    if (! automaticSubmission) {
      usePassword()
    }
  } catch (e) {
    if (! automaticSubmission) {
      usePassword()
    }

    return
  } finally {
    form.reset('password')
    submitting.value = false
  }
}

const submitIfNoPasswordField = (event) => {
  if (! showPasswordField.value && form.username) {
    event.preventDefault()
    submit()
  }
}

if (supportsWebAuthn) {
  onMounted(() => submit(true))
}

</script>

<template>
  <PageLayout :title="tenant ? 'Logging in to:' : 'Log in'" :subtitle="tenant?.name">
    <Head title="Log in" />

    <PageContent>
      <div v-if="loggedOut" class="flex bg-primary-300 text-primary-900 text-sm p-3 mb-5">
        <div class="flex-shrink-0">
          <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" aria-hidden="true">
            <path fill="currentColor" d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 48c110.532 0 200 89.451 200 200 0 110.532-89.451 200-200 200-110.532 0-200-89.451-200-200 0-110.532 89.451-200 200-200m140.204 130.267l-22.536-22.718c-4.667-4.705-12.265-4.736-16.97-.068L215.346 303.697l-59.792-60.277c-4.667-4.705-12.265-4.736-16.97-.069l-22.719 22.536c-4.705 4.667-4.736 12.265-.068 16.971l90.781 91.516c4.667 4.705 12.265 4.736 16.97.068l172.589-171.204c4.704-4.668 4.734-12.266.067-16.971z" class=""></path>
          </svg>
        </div>
        <div class="ml-2 font-medium subpixel-antialiased">Logged out successfully</div>
      </div>

      <div v-if="status" class="mb-4 font-medium text-sm text-green-600">
        {{ status }}
      </div>

      <form @submit.prevent="submit()" :disabled="submitting || form.processing">
        <div>
          <TextInput
            id="username"
            name="username"
            class="mt-1 block w-full"
            v-model="form.username"
            :placeholder="tenant?.options?.['login_placeholder'] ?? 'Enter your email address/username'"
            required
            autofocus
            autocomplete="username webauthn"
            @keydown.tab="submitIfNoPasswordField"
          />

          <InputError class="mt-2" :message="form.errors.username" />
          <InputError class="mt-2" :message="form.errors.answer" />
        </div>

        <transition
          enter-active-class="duration-300 ease-out"
          enter-from-class="transform opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="duration-200 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="transform opacity-0">
          <div v-if="showPasswordField" class="mt-4">
            <TextInput
              ref="passwordField"
              id="password"
              name="password"
              type="password"
              class="mt-1 block w-full"
              v-model="form.password"
              placeholder="Enter your password"
              required
              autocomplete="current-password"
            />

            <InputError class="mt-2" :message="form.errors.password" />
          </div>
        </transition>

        <div class="block mt-4">
          <label class="flex items-center">
            <Checkbox name="remember" v-model:checked="form.remember" />
            <span class="ms-2 text-sm text-gray-400">Remember me</span>
          </label>
        </div>

        <div class="flex items-center justify-end mt-4">
          <Link
            v-if="showPasswordField && canResetPassword"
            href="/forgot-password"
            class="underline text-sm text-gray-400 hover:text-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800"
          >
            Forgot your password?
          </Link>

          <PrimaryButton class="ms-4" :class="{ 'opacity-25': submitting || form.processing }" :disabled="submitting || form.processing">
            {{ showPasswordField ? 'Log in' : 'Continue' }}
          </PrimaryButton>
        </div>
      </form>
    </PageContent>
  </PageLayout>
</template>
